<template>
  <div class="customer">
    <template v-if="customer">
      <div class="fgg-container bg-accent-500">
        <h2>{{ customer.first_name }} {{ customer.last_name }}</h2>
        <div class="font-bold">
          <div class="inline-block w-8">
            <font-awesome-icon
              icon="envelope"
              color="primary"
            ></font-awesome-icon>
          </div>
          <div class="inline-block">{{ customer.email }}</div>
        </div>
        <div v-if="customer.address">
          <div class="inline-block w-8">
            <font-awesome-icon icon="road" color="primary"></font-awesome-icon>
          </div>
          <div class="inline-block">{{ customer.address }}</div>
        </div>
        <div v-if="customer.country || customer.zip || customer.city">
          <div class="inline-block w-8">
            <font-awesome-icon icon="city" color="primary"></font-awesome-icon>
          </div>
          <div class="inline-block">
            {{ customer.zip }}
            {{ customer.city }}
          </div>
        </div>
        <div v-if="customer.mobile">
          <div class="inline-block w-8">
            <font-awesome-icon icon="phone" color="primary"></font-awesome-icon>
          </div>
          <div class="inline-block">
            {{ customer.mobile }}
          </div>
        </div>
      </div>
      <div class="fgg-container" v-if="profile && card">
        <h4>Derzeit zugewiesen:</h4>
        <div class="profile">
          <div class="flex">
            <div class="flex-1">
              <div class="font-bold text-lg"># {{ card.id }}</div>
            </div>
            <div class="flex-initial">
              <font-awesome-icon
                icon="check"
                size="lg"
                color="green"
                class="mt-1"
              ></font-awesome-icon>
            </div>
          </div>
          <table class="text-base mt-3">
            <tr>
              <td class="font-bold pr-3">Aufgeladen am:</td>
              <td>{{ formatDatetime(profile.updated_at) }}</td>
            </tr>
            <tr>
              <td class="font-bold pr-3">Profiltyp:</td>
              <td>{{ profile.type.name }}</td>
            </tr>
            <tr>
              <td class="font-bold pr-3">Guthaben:</td>
              <td>
                {{ profile.volume_remaining }}
                {{ profile.volume_unit }}
              </td>
            </tr>
            <tr>
              <td class="font-bold pr-3">Restbetrag:</td>
              <td>
                {{ formatMoney(profile.money_remaining) }}
                {{ profile.money_unit }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
    <div v-else class="text-center mt-32">Kein Kunde ausgewählt!</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "Customer",

  computed: {
    ...mapState(["customer", "card", "profile"]),
  },

  methods: {
    /**
     * Format money.
     *
     * @param value
     * @returns {string}
     */
    formatMoney(value) {
      return (value / 100).toFixed(2);
    },

    /**
     * Format to date time.
     *
     * @param value
     * @returns {*}
     */
    formatDatetime(value) {
      return moment(value).format("DD.MM.YYYY HH:mm") + " Uhr";
    },
  },
};
</script>

<style scoped></style>
